export interface MzcClientOptions {
    host?: string;
    appKey: string;
    loginRedirectUri: string;
    logoutRedirectUri?: string;
}

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
}

export enum HttpDataType {
    TEXT = 'text',
    JSON = 'json',
}

interface HttpJsonBody {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface HttpRequest {
    uri: string;
    method?: HttpMethod;
    params?: {[key: string]: string | number | null};
    body?: HttpJsonBody | string | number | null;
    responseType?: HttpDataType;
    timeout?: number;
}

export enum MzcApi {
    AuthStatus = 'api/v1/my/auth/status',
    UserInfo = 'api/v1/my/info',
    UserInfoV2 = 'api/v2/my/info',
    UserDevices = 'api/v1/my/devices',
    UserDeviceSummary = 'api/v1/my/devices/summary',
    UserReminderStatus = 'api/v1/my/reminder_status',
    Logout = 'oauth2/logout',
}
